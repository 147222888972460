import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Layout from '../layouts'
import HeroSection from '../components/hero'
import ServicesSection from '../components/services'
import Contact from '../components/contact'
import ContactBanner from '../components/contactBanner'
import InstagramSection from '../components/instagramSection'
import QuoteBanner from '../components/quoteBanner'
import { commonTags } from '../config/constants'

const IndexPage = () => (
  <StaticQuery
    query={graphql`
      query IndexQuery {
        siteMetadata: allContentfulSiteMetadata {
          edges {
            node {
              telephone
              instagram
              email
              behance
            }
          }
        }
        home: allContentfulPage(filter: { slug: { eq: "/" } }) {
          edges {
            node {
              title
              ogTitle
              ogDescription
              ogKeywords
              ogImage {
                description
                file {
                  url
                  contentType
                  details {
                    size
                    image {
                      width
                      height
                    }
                  }
                }
              }
              favIco {
                file {
                  url
                }
              }
              pageSections {
                ... on ContentfulQuoteBanner {
                  ... QuoteFragment
                }
                ... on ContentfulPageSection {
                  ... SectionFragment
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const metadata = data.siteMetadata.edges[0].node
      if (!metadata) { return null }

      const {
        ogTitle, ogKeywords, ogDescription, ogImage, favIco, pageSections,
      } = data.home.edges[0].node

      return (
        <div>
          <Helmet
            title={ogTitle}
            meta={commonTags(ogTitle, ogDescription, ogKeywords, ogImage)}
          >
            <link rel='icon' type='image/png' href={favIco.file.url}  />
            <link href='https://fonts.googleapis.com/css?family=Coming+Soon' rel='stylesheet' />
            <link href='https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400;0,600;1,400&display=swap' rel='stylesheet' />
          </Helmet>
          <Layout>
            {pageSections.map((pageSection) => {
              if (pageSection.__typename === 'ContentfulQuoteBanner') {
                return (
                  <QuoteBanner
                    phrase={pageSection.description && pageSection.description.description}
                    author={pageSection.author}
                  />
                )
              }
              switch (pageSection.name) {
                case 'Home Hero':
                  return <HeroSection items={pageSection.items} metadata={metadata} />
                case 'Services Section':
                  return (
                    <ServicesSection
                      items={pageSection.items}
                      title={pageSection.title}
                      description={pageSection.description}
                    />)
                case 'Contact Banner':
                  return (
                    <ContactBanner
                      description={pageSection.description}
                    />)
                case 'Contact Form':
                  return (
                    <Contact
                      title={pageSection.title}
                      description={pageSection.description && pageSection.description.description}
                    />)
                case 'Instagram Section':
                  return (
                    <InstagramSection
                      description={pageSection.description && pageSection.description.description}
                      metadata={metadata}
                    />
                  )
                default:
                  return null
              }
            })}
          </Layout>
        </div>)
    }}
  />)

export const siteMetadataQuery = graphql`
  fragment SiteMetadataFragment on ContentfulSiteMetadata {
    telephone
    instagram
    email
    behance
  }
`

export const pageSectionQuery = graphql`
  fragment SectionFragment on ContentfulPageSection {
    __typename
    name
    title
    description {
      description
    }
    items {
      ... ContactBannerFragment
      ... NavBarFragment
      ... PhotoAlbumFragment
      ... TextSectionFragment
    }
  }
`

export const contactBannerQuery = graphql`
  fragment ContactBannerFragment on ContentfulContactBanner {
    __typename
    description {
      description
    }
  }
`

export const quoteQuery = graphql`
  fragment QuoteFragment on ContentfulQuoteBanner {
    __typename
    author
    description {
      description
    }
  }
`

export const navBarQuery = graphql`
  fragment NavBarFragment on ContentfulNavBar {
    __typename
    items {
      id
      title
      link
      scrollTo
    }
    logo {
      file {
        url
      }
    }
  }
`

export const photoAlbumQuery = graphql`
  fragment PhotoAlbumFragment on ContentfulFotoAlbum {
    __typename
    header {
      header
    }
    footer {
      footer
    }
    photos {
      file {
        fileName
        url
      }
      resize {
        width
        height
      }
      sizes(maxWidth: 1200) {
        aspectRatio
        src
      }
    }
  }
`

export const textSectionQuery = graphql`
  fragment TextSectionFragment on ContentfulTextSection {
    __typename
    title
    description {
      description
    }
    link
    imagePosition
    image {
      file {
        fileName
        url
        contentType
        details {
          size
          image {
            width
            height
          }
        }
      }
    }
  }
`

export default IndexPage
